import {FormikErrors, FormikValues} from 'formik'
import {Constraints} from 'services/apix'

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// e.g taxAgent -> ['tax', 'agent']
function splitCamelCase(string: string): string[] {
  return string.split(/(?=[A-Z])/).map((s: string) => s.toLowerCase())
}

type FormErrors<FormValues> = FormikErrors<FormValues>

/**
 * Map form errors to their respective fields based on error response
 *
 * @param constraints
 */
function parseFormErrors<T>(constraints: Constraints[]): FormErrors<T> {
  return constraints.reduce((acc: any, constraint: Constraints) => {
    if (constraint.children.length > 0) {
      return {
        ...acc,
        [constraint.property]: {
          ...acc[constraint.property],
          ...parseFormErrors(constraint.children),
        },
      }
    }

    if (constraint.constraints) {
      const {property, constraints} = constraint
      const [firstKey] = Object.keys(constraints)

      return {
        ...acc,
        [property]: constraints[firstKey],
      }
    }
  }, {})
}

/**
 * Prepends currency sign to the number
 * @param value number
 */
function formatNumberWithCurrency(value: number | string): string {
  let str = ''
  let prefix = ''

  if (value) {
    // Cast to number
    if (typeof value === 'string') {
      value = parseFloat(value)
    }

    if (value < 0) {
      prefix = '-'
    }

    value = Math.abs(value).toFixed(2)

    str = `${prefix}${process.env.REACT_APP_CURRENCY}${value.replace(
      /\B(?=(\d{3})+(?!\d))/g, // split 100, 000
      ','
    )}`
  }

  return str
}

/**
 * Appends percentage sign to the number
 * @param value
 */
function formatNumberWithPercentage(value: number) {
  return value ? `${value.toFixed(2)}%` : '—'
}

/**
 * Formats tax rate based on it's type
 * @param taxRate
 */
function formatTaxRate(taxRate: TaxRate): string {
  if (taxRate.type === 'fixed') {
    return formatNumberWithCurrency(taxRate.value)
  }

  //Otherwise percentage
  return formatNumberWithPercentage(taxRate.value)
}

/**
 * Shortens and appends a 'K' for values in the thousands
 * @param
 */

function formatNumberByThousands(value: number): string {
  if (value >= 1000) {
    return `${Math.floor(value / 1000)}K`
  }

  return `${value}`
}

/**
 * Format codes or chapters
 * @param codesOrChapters
 */
function formatCodesOrChapters(codesOrChapters: string[]) {
  return codesOrChapters.length
    ? codesOrChapters.map(item => formatCodeOrChapter(item)).join(', ')
    : '-'
}

function formatCodeOrChapter(code: string) {
  return code.length > 2 ? code : `Chapter ${parseInt(code)}`
}

function formatAddress({address}: Pick<User, 'address'>) {
  return `${address.line2 || ''} ${address.line1 || ''}\n ${address.city ||
    ''}, ${address.province || ''}\n ${address.postalCode ||
    ''}, ${address.country || ''}`
}

function removeEmptyValuesFromObj(obj: any): any {
  Object.keys(obj).forEach(key => !obj[key] && delete obj[key])
  return obj
}

/**
 * Returns true if objects are equal
 * @param newObj
 * @param prevObj
 */
function shallowCompare<T>(prevObj: T, newObj: T): boolean {
  for (let key in newObj) {
    if (newObj[key] !== prevObj[key]) return false
  }
  return true
}

/**
 * Returns path to the first error, even if error is nested object
 * Ex: {rate: {value: "Missing value", type: "Missing type"}} will return rate.value
 * @param errors
 * @param path
 */
function errorPath(errors: {[index: string]: any}, path = '') {
  const firstKey = Object.keys(errors)[0]
  if (firstKey) {
    if (!path) {
      path = firstKey
    } else {
      path = `.${firstKey}`
    }

    if (typeof errors[firstKey] === 'object') {
      path += errorPath(errors[firstKey], path)
    }
  }

  return path
}

export {
  capitalizeFirstLetter,
  splitCamelCase,
  parseFormErrors,
  formatTaxRate,
  formatNumberWithPercentage,
  formatNumberWithCurrency,
  formatCodesOrChapters,
  formatCodeOrChapter,
  formatAddress,
  removeEmptyValuesFromObj,
  formatNumberByThousands,
  shallowCompare,
  errorPath,
}
