import React from 'react'
import styled from 'shared/theme'
import {number} from 'yup'

export interface PaddingProps {
  padding?: string | number
  pt?: number
  pl?: number
  pb?: number
  pr?: number
  pv?: number
  ph?: number
}

function getPadding(props: PaddingProps): string {
  const {padding, pt, pl, pr, pb, pv, ph} = props

  let style = ``

  if (typeof padding === 'number') {
    style += `padding: ${padding}px;`
  }

  if (typeof padding === 'string') {
    style += `padding: ${padding};`
  }

  if (pt) {
    style += `padding-top: ${pt}px;`
  }

  if (pb) {
    style += `padding-bottom: ${pb}px;`
  }

  if (pv) {
    style += `padding-top: ${pv}px; padding-bottom: ${pv}px;`
  }

  if (pl) {
    style += `padding-left: ${pl}px;`
  }

  if (pr) {
    style += `padding-right: ${pr}px;`
  }

  if (ph) {
    style += `padding-left: ${ph}px; padding-right: ${ph}px;`
  }

  return style
}

export interface MarginProps {
  margin?: string | number
  mt?: number
  ml?: number
  mb?: number
  mr?: number
  mv?: number
  mh?: number
}

function getMargin(props: MarginProps): string {
  const {margin, mt, ml, mr, mb, mv, mh} = props

  let style = ``

  if (typeof margin === 'number') {
    style += `margin: ${margin}px;`
  }

  if (typeof margin === 'string') {
    style += `margin: ${margin};`
  }

  if (mt) {
    style += `margin-top: ${mt}px;`
  }
  if (mb) {
    style += `margin-bottom: ${mb}px;`
  }

  if (mv) {
    style += `margin-top: ${mv}px; margin-bottom: ${mv}px`
  }

  if (ml) {
    style += `margin-left: ${ml}px;`
  }
  if (mr) {
    style += `margin-right: ${mr}px;`
  }

  if (mh) {
    style += `margin-left: ${mh}px; margin-right: ${mh}px;`
  }

  return style
}

type SpacerProps = PaddingProps & MarginProps

const Spacer = styled.div<SpacerProps>`
  ${getPadding}
  ${getMargin}
`

export {Spacer}
