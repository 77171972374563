import React, {useState, useEffect, useCallback} from 'react'
import {netSweeperApi, ApixEvents} from 'services/apix'
import {Spin, Col, Row} from 'antd'

interface IAuthProviderContext {
  user: User | null
  error?: any
  setUser?: (user: User) => void
  resetUser?: () => void
}

const AuthContext = React.createContext<IAuthProviderContext>({
  user: null,
})

const AuthProvider: React.FunctionComponent = props => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState<User | null>(null)

  const resetUser = useCallback(() => {
    setData(null)
  }, [])

  useEffect(() => {
    setLoading(true)

    // Listen when API dispatch failed auth event to reset the user
    // it will trigger rerender without user and has to show login screen
    // @see App.txs
    window.addEventListener(ApixEvents.authFailed, resetUser)

    netSweeperApi
      .getCurrentUser()
      .then(fetchedUser => {
        setData(fetchedUser)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        setError(error)
      })

    return () => {
      window.removeEventListener(ApixEvents.authFailed, resetUser)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user: data,
        error,
        setUser: setData,
        resetUser,
      }}>
      {loading ? <LoadingScreen /> : props.children}
    </AuthContext.Provider>
  )
}

const LoadingScreen = () => (
  <Spin className='initial-loader' tip='Initializing...'>
    <Row
      style={{
        background: 'rgba(255, 255, 255, 0.5)',
        height: '100vh',
        width: '100vw',
      }}
    />
  </Spin>
)

export {AuthContext, AuthProvider}
