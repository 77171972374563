import React from 'react'

import {Spacer} from 'shared/ui/Spacer'
import {Skeleton} from 'antd'

function Loading() {
  return (
    <Spacer padding={40}>
      <Skeleton active />
    </Spacer>
  )
}

export default Loading
