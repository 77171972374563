import React, {useContext, Suspense} from 'react'
import {Provider as ThemeProvider} from 'shared/theme'
import {DateProvider} from 'shared/date-provider'
import {AuthProvider, AuthContext} from 'shared/auth-provider'
import SuspenseLoading from 'shared/ui/SuspenseLoading'

const Admin = React.lazy(() => import('./pages/admin-page'))
const Merchant = React.lazy(() => import('./pages/merchant-page'))
const TaxAgent = React.lazy(() => import('./pages/tax-agent-page'))
const Login = React.lazy(() => import('./pages/Login/login-page'))

function App() {
  return (
    <ThemeProvider>
      <DateProvider>
        <AuthProvider>
          <UserRouter />
        </AuthProvider>
      </DateProvider>
    </ThemeProvider>
  )
}

interface UserRouterProps {}

/**
 * Render the template for the active (logged in) user type
 *
 * @param props user: User
 */
function UserRouter(props: UserRouterProps) {
  const {user, error} = useContext(AuthContext)

  const renderScreen = () => {
    if (user) {
      const {type} = user

      if (type === 'admin') {
        return <Admin user={user} />
      }

      if (type === 'merchant') {
        return <Merchant user={user} />
      }

      if (type === 'taxAgent') {
        return <TaxAgent user={user} />
      }
    }

    return <Login />
  }

  return <Suspense fallback={<SuspenseLoading />}>{renderScreen()}</Suspense>
}

export default App
