import React from 'react'
import * as styledComponents from 'styled-components'

export type Colors = {
  lightBlue: string
  navy: string
  black: string
  darkGreen: string
  lightGreen: string
  black85: string
  black65: string
  black45: string
  black25: string
  black07: string
  black04: string
  perriwinkle10: string
  strawberry: string
  white: string
  lightGray: string
  borderColor: string
  blue: string
  blue15: string
  red: string
}

const themeColors: Colors = {
  lightBlue: '#409fff',
  navy: '#262c48',
  black: '#000000',
  darkGreen: '#078930',
  lightGreen: '#0bca47',
  black85: 'rgba(0, 0, 0, 0.85)',
  black65: 'rgba(0, 0, 0, 0.65)',
  black45: 'rgba(0, 0, 0, 0.45)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black07: 'rgba(0,0,0,0.07)',
  black04: 'rgba(0, 0, 0, 0.04)',
  perriwinkle10: 'rgba(164, 201, 238, 0.1)',
  strawberry: '#f4222c',
  white: 'white',
  lightGray: '#e8e8e8',
  borderColor: '#d9d9d9',
  blue: 'rgba(229, 241, 254, 1)',
  blue15: 'rgba(64, 159, 255, 0.15)',
  red: '#ff0000',
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  IThemeInterface
>

export interface IThemeInterface {
  borderRadius: string
  colors: Colors
  sidebarWidth: string
  fonts: {
    medium: string
    regular: string
    bold: string
  }
}

export const theme = {
  borderRadius: '5px',

  colors: themeColors,

  sidebarWidth: '128',

  fonts: {
    medium: 'Helvetica Neue MD Pro',
    regular: 'Helvetica Neue LT Pro',
    bold: 'Helvetica Neue BD Pro',
  },
}

// load fonts:
import regularWoff from '../fonts/HelveticaNeueLTPro-Lt.woff'
import regularWoff2 from '../fonts/HelveticaNeueLTPro-Lt.woff2'
import mediumWoff from '../fonts/HelveticaNeueLTPro-Md.woff'
import mediumWoff2 from '../fonts/HelveticaNeueLTPro-Md.woff2'
import boldWoff from '../fonts/HelveticaNeueLTPro-Bd.woff'
import boldWoff2 from '../fonts/HelveticaNeueLTPro-Bd.woff2'

const Fonts = createGlobalStyle`
  @font-face {
    font-family: '${props => props.theme.fonts.medium}';
    src: url(${mediumWoff2}) format('woff2'),
      url(${mediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: '${props => props.theme.fonts.regular}';
    src: url(${regularWoff2}) format('woff2'),
    url(${regularWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: '${props => props.theme.fonts.bold}';
    src: url(${boldWoff2}) format('woff2'),
      url(${boldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
  }

  // html > body {
  //   font-family: ${props => props.theme.fonts.regular};
  //   -webkit-font-smoothing: antialiased;
  //   -moz-osx-font-smoothing: grayscale;
  // }

  .ant-spin-nested-loading > div > .ant-spin.initial-loader {
    max-height: 100%;
  }
`

const GlobalDatePickerStyles = createGlobalStyle`
  .ant-calendar-picker-container {

    .ant-calendar-input-wrap {
      border-bottom: 0;
      padding-left: 21px;
      padding-right: 21px;
    }

    .ant-calendar-range {
      margin-top: 44px;
      width: 521px;
      border: 2px solid ${props => props.theme.colors.lightGreen};
    }

    .ant-calendar-header {
      line-height: 35px;
      height: 35px;

      > div {
        background-color: ${props => props.theme.colors.lightBlue};  
      }

      .ant-calendar-prev-century-btn, 
      .ant-calendar-next-century-btn,
      .ant-calendar-prev-decade-btn,
      .ant-calendar-next-decade-btn,
      .ant-calendar-prev-month-btn,
      .ant-calendar-next-month-btn, 
      .ant-calendar-prev-year-btn, 
      .ant-calendar-next-year-btn,
      .ant-calendar-month-select,
      .ant-calendar-year-select {
        line-height: inherit;
        text-transform: uppercase;
        color: white;
        font-size: 12px;
        font-weight: 500;
      }
    }
  
    .ant-calendar-body {
      padding: 0 21px 16px;
    }

    .ant-calendar-range .ant-calendar-body {
      border-top: 0;
    }
  
    .ant-calendar-range-left {
      padding-top: 19px;

      .ant-calendar-header {
        padding-left: 21px;
        padding-right: 7.5px;
      }

      .ant-calendar-input-wrap {
        display: none;
        width: 130px;
        margin-left: 260px;
      }

      .ant-calendar-body {
        padding-right: 7.5px;
      }
    }

    .ant-calendar-range-middle {
      display: none;
      padding: 0;
      padding-left: 245px;
    }
  
    .ant-calendar-range-right {
      padding-top: 19px;

      .ant-calendar-input-wrap {
        display: none;
        width: 130px;
        margin: 0 11px 0 auto;
      }

      .ant-calendar-date-input-wrap {
        margin-left: 0;
      }

      .ant-calendar-header {
        padding-right: 21px;
        padding-left: 7.5px;
      }
      
      .ant-calendar-body {
        padding-left: 7.5px;
      }
    }

    .ant-calendar-table  > thead {
      background-color: ${props => props.theme.colors.lightBlue};
      border-right: 1px solid ${props => props.theme.colors.lightBlue};
    }

    .ant-calendar-column-header-inner {
      color: ${props => props.theme.colors.white};
      font-size: 12px;
      font-weight: 500;
    }
  
    .ant-calendar-tbody tr {
      border-bottom: 1px solid #8f8f8f;
  
      &:first-child {
        border-top: 1px solid #8f8f8f;
      }
  
      td {
        &:first-child {
          border-left: 1px solid #8f8f8f;
        }
      }
    }
  
    td.ant-calendar-cell {
      padding: 0;
      border-right: 1px solid #8f8f8f;
    }
    
    .ant-calendar-date {
      width: auto;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      font-weight: 500;
      margin: 0;

      &:hover {
        background-color: rgba(7, 137, 48, 0.7);
      }
    }

    .ant-calendar-today {

      .ant-calendar-date {  
        border-color: transparent;
        border-radius: 0;
        color: white;
        font-weight: normal;
      }

      &:not(.ant-calendar-selected-day) {
        .ant-calendar-date {
          color: ${props => props.theme.colors.black65};
        }
      }
    }
  
    .ant-calendar-range .ant-calendar-in-range-cell{
      .ant-calendar-date {
        color: ${props => props.theme.colors.white};
      }
  
      &::before {
        top: 0;
        bottom: 0;
        background-color: rgba(7, 137, 48, 0.8);
      }
    }
  
    .ant-calendar-selected-date .ant-calendar-date, 
    .ant-calendar-selected-start-date .ant-calendar-date, 
    .ant-calendar-selected-end-date .ant-calendar-date {
      background-color: #078930;
      border-radius: 0;
      border: 0;

      &:hover {
        background-color: rgba(7, 137, 48, 0.9);
      }
    }

    .ant-calendar-footer {
      line-height: 46px;
    }

    .ant-calendar-footer-extra {
      .ant-tag {
        text-align: center;
        margin-right: 13px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .ant-calendar-header {
      & > .ant-calendar-month-panel,
      & > .ant-calendar-decade-panel,
      & > .ant-calendar-year-panel {
        background-color: white;
      }
    }
  }


  .ant-select-tree-title {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

function Provider({children}: {children: any}) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Fonts />
        <GlobalDatePickerStyles />
        {children}
      </>
    </ThemeProvider>
  )
}

export default styled
export {css, createGlobalStyle, keyframes, ThemeProvider, Provider}
