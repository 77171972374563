import React, {ReactChildren, ReactNode, useState, useCallback} from 'react'
import moment, {Moment} from 'moment'
import {RangePickerValue} from 'antd/lib/date-picker/interface'

interface IDateContext {
  startDate: moment.Moment
  endDate: moment.Moment
  lastUpdate: moment.Moment
  setDates: (dates: RangePickerValue) => void
  setLastUpdated: () => void // This method has to be called once "Last updated on" need to be updated. Mostly when date related to transactions was fetched
}

const DateContext = React.createContext<IDateContext>({
  startDate: moment().startOf('month'),
  endDate: moment(),
  setDates: (dates: RangePickerValue) => {},
  lastUpdate: moment(),
  setLastUpdated: () => {},
})

function DateProvider({children}: {children: ReactNode}) {
  const [lastUpdate, setLastUpdated] = useState<Moment>(moment())
  const [startDate, setStartDate] = useState<Moment>(moment().startOf('month'))
  const [endDate, setEndDate] = useState<Moment>(moment())

  const setDates = (dates: RangePickerValue) => {
    if (dates.length > 1) {
      if (dates[0]) {
        setStartDate(dates[0])
      }

      if (dates[1]) {
        setEndDate(dates[1])
      }
    }
  }

  const handleLastUpdate = () => {
    setLastUpdated(moment())
  }

  return (
    <DateContext.Provider
      value={{
        setDates,
        startDate,
        endDate,
        lastUpdate,
        setLastUpdated: handleLastUpdate,
      }}>
      {children}
    </DateContext.Provider>
  )
}

export {DateContext, DateProvider}
