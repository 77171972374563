// TODO: abstract localStorage dependency into generic 'store'
// not sure if localStorage is the best way to store this data
// and we can swap out for something else if we capture the same behaviour

export interface Storage {
  save: (key: string, value: any) => void
  get: (key: string) => any
  remove: (key: string) => void
  listen: (key: string, callback: (newValue: any) => void) => () => void
  keys: {[storageKey: string]: string}
}

function save(key: string, value: any) {
  const serialized = JSON.stringify(value)
  localStorage.setItem(key, serialized)
  dispatchStorageEvent(key)
}

function get(key: string) {
  const serialized = localStorage.getItem(key)
  if (serialized) {
    try {
      const value = JSON.parse(serialized)
      return value
    } catch (err) {
      return null
    }
  }

  return null
}

function remove(key: string) {
  localStorage.removeItem(key)
  dispatchStorageEvent(key)
}

// 'storage' events only fire on separate (active) windows
// but we want to subscribe to localStorage changes in the current window as well
// this will keep the app in sync with localStorage values (user / token data)
function dispatchStorageEvent(key: string) {
  const event = new StorageEvent('storage', {
    key: key,
    newValue: localStorage.getItem(key),
  })

  window.dispatchEvent(event)
}

function listen(key: string, callback: any) {
  function listener(event: StorageEvent) {
    if (event.key === key) {
      let value = null
      if (event.newValue) {
        value = JSON.parse(event.newValue)
      }

      callback(value)
    }
  }

  window.addEventListener('storage', listener)

  // useEffect() needs this to properly unmount components
  return function() {
    window.removeEventListener('storage', listener)
  }
}

const keys = {
  token: 'token',
  user: 'my-user-key',
  refreshToken: 'tr',
  tokenExpirationDate: 'te',
}

export default {
  save,
  get,
  remove,
  listen,
  keys,
}
