import storage, {Storage} from './local-storage'
import axios, {AxiosResponse, AxiosError, AxiosInstance} from 'axios'
import {UserFormValues} from 'shared/manage-users/user-form'
import {LoginFormValues} from '../pages/Login/Components/LoginForm'
import {parseFormErrors, removeEmptyValuesFromObj} from 'shared/helpers'
import {TaxFormValues} from 'shared/manage-taxes/tax-form'
import {
  TransactionFilterOptions,
  TransactionQuery,
} from 'shared/manage-transactions/transaction-filters'

import moment from 'moment'
import {ChapterFormValues} from 'shared/manage-hs-codes/chapter-form'

interface ApiOptions {
  readonly storage: Storage
  axios: AxiosInstance
  readonly tokenRefreshTreshold: number
}

interface Response<T> {
  statusCode: number
  status: boolean
  data: T
}

interface ResponseWithMeta<T, M> extends Response<T> {
  meta: M
}

interface Token {
  token: string
  refreshToken: string
  expiresAt: string
}

interface FetchedHSChapter extends Entity {
  code: null | number
  label: string
  name: string
  type: HSChapterType
  codes: FetchedHSCode[]
}

// this is almost the same as HSCode but missing parent chapter object
interface FetchedHSCode extends Entity {
  type: HSChapterType
  code: null | number
  label: string
}

interface FetchedHSCodeDetail extends FetchedHSCode {
  description?: string
  noOfDigits?: number
  chapter: Partial<FetchedHSChapter>
}

export interface PaginatedResponse {
  pagination: {
    last: number
    current: number
    perPage: number
  }
  data: {
    total: number
  }
  sorting: {
    by: string
    order: string
  }
}

// Responses
type TokenResponse = Response<Token>
type UserResponse = Response<User>
type UsersResponse = Response<User[]>
type TaxesResponse = Response<Tax[]>
type TaxResponse = Response<Tax>
type HSCodeResponse = Response<FetchedHSChapter[]>
type HSChapterResponse = Response<HSChapter>
type HSChaptersResponse = ResponseWithMeta<HSChapter[], PaginatedResponse>
type HSChapterCodesResponse = ResponseWithMeta<HSCode[], PaginatedResponse>
type HSCodeDetailResponse = Response<FetchedHSCodeDetail>
type HSCodesUpdateResponse = Response<HSCode>
export type TransactionsResponse = ResponseWithMeta<
  Transaction[],
  PaginatedResponse
>
type TransactionsSummaryResponse = Response<TransactionsSummary>
type TransactionResponse = Response<Transaction>
type TransactionFilterOptionsResponse = Response<TransactionFilterOptions>
type TransactionGroupResponse = ResponseWithMeta<
  TransactionGroup[],
  PaginatedResponse
>

// Errors:
type ErrorResponse = Response<{error: string}>
type NotFound = Response<{error: string; message: string}>
type ValidationError = Response<{
  error: string
  message: Constraints[] // @TODO maybe rename it to constrains very confusing at this point
}>

export const ApixEvents = {
  authFailed: 'apix.authFailed',
}

export interface Constraints {
  property: string
  children: Constraints[]
  constraints?: {[constraint: string]: string}
}

export default class Apix implements ApiOptions {
  endpoints: any
  readonly storage: Storage
  axios: AxiosInstance
  tokenRefreshTreshold: number //miliseconds
  isRefreshingToken = false
  authFailed = new Event(ApixEvents.authFailed)

  constructor(options: ApiOptions) {
    this.endpoints = {}
    this.storage = options.storage
    this.axios = options.axios
    this.tokenRefreshTreshold = options.tokenRefreshTreshold // token refresh treshold

    // check if there is already a token set in localStorage and use it:
    const token = this.storage.get(this.storage.keys.token)
    if (token) {
      this.setAuthHeader(token)
    }

    this.axios.interceptors.request.use(
      config => {
        // Check for token and cancel any request if we don't have token
        // Allow login request without token
        if (
          !Boolean(this.storage.get(this.storage.keys.token)) &&
          !(config.url && config.url.indexOf('login') > -1)
        ) {
          config = {
            ...config,
            cancelToken: new axios.CancelToken(cancel =>
              cancel('No token provided')
            ),
          }
        }

        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    this.axios.interceptors.response.use(
      response => {
        // Maybe its time to revalidate the token
        const tokenExpire = this.storage.get(
          this.storage.keys.tokenExpirationDate
        )
        if (tokenExpire) {
          // Refresh token if we less than treshold milliseconds to expire date
          if (
            moment(tokenExpire).diff(moment(), 'milliseconds') <=
              this.tokenRefreshTreshold &&
            !this.isRefreshingToken
          ) {
            this.isRefreshingToken = true
            this.maybeResignRequest().then(() => {
              this.isRefreshingToken = false
            })
          }
        }

        return response
      },
      (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          window.dispatchEvent(this.authFailed)
          this.invalidate()
        }

        return Promise.reject(error)
      }
    )
  }

  /**
   * Login method
   * @param email string
   * @param password string
   */
  login = async (email: string, password: string): Promise<User> => {
    return new Promise((resolve, reject) => {
      this.axios
        .post('/login', {email, password})
        .then((response: AxiosResponse<TokenResponse>) => {
          this.saveTokens(response.data.data)

          return this.axios.get('/me')
        })
        .then((response: AxiosResponse<UserResponse>) => {
          const {data: user} = response.data

          resolve(user)
        })
        .catch((error: AxiosError) => {
          this.invalidate()

          if (error.response) {
            const response: ErrorResponse = error.response.data

            // validation error case
            if (response.statusCode === 422) {
              const {
                data: {message},
              } = response as ValidationError

              const errors = parseFormErrors<LoginFormValues>(message)
              reject(errors)
            }

            // user not found case
            if (response.statusCode === 404) {
              const {
                data: {message},
              } = response as NotFound

              reject({
                password:
                  'The email/password combination used was not found on the system.',
              })
            }
          }

          // some kind of network error
          reject({message: 'Network error!'})
        })
    })
  }

  /**
   * Get current logged in user
   */
  getCurrentUser = async (): Promise<User> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get('/me')
        .then((response: AxiosResponse<UserResponse>) => {
          const {data: user} = response.data

          resolve(user)
        })
        .catch((error: AxiosError) => {
          this.invalidate()
          reject(error)
        })
    })
  }

  /**
   * Logs user out by removing key from local storage
   */
  logout = async () => {
    // not sure what /logout does on BE, but we need the token to hit it
    // so we should wait for response before invalidating
    return this.axios
      .delete('/logout')
      .then(() => {
        this.invalidate()
      })
      .catch(err => {
        // still log them out
        this.invalidate()
      })
  }

  getUsers = async (params: any): Promise<User[]> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get('/users', {params})
        .then((response: AxiosResponse<UsersResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  getUser = async (params: {id: string}): Promise<User> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/users/${params.id}`)
        .then((response: AxiosResponse<UserResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  createUser = async (values: UserFormValues): Promise<User> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .post('/users', this._transformUserForRequest(values))
        .then((response: AxiosResponse<UserResponse>) => {
          let user = response.data.data
          if (values.certificateFile) {
            this.uploadCerificate(user, values.certificateFile)
              .then(response => {
                resolve(response)
              })
              .catch(error => {
                reject(error)
              })
          } else {
            resolve(user)
          }
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            const response: ErrorResponse = error.response.data

            if (response.statusCode === 422) {
              const {
                data: {message},
              } = response as ValidationError

              const errors = parseFormErrors<UserFormValues>(message)

              reject(errors)
            }

            if (response.statusCode === 400) {
              reject({message: 'Duplicate user found.'})
            }
          }

          reject(error)
        })
    })
  }

  updateUser = async (values: UserFormValues): Promise<User> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .put(`/users/${values.id || ''}`, this._transformUserForRequest(values))
        .then((response: AxiosResponse<UserResponse>) => {
          let user = response.data.data

          // Delete certificate
          if (user.type === 'merchant' && !values.certificateFile) {
            this.deleteCertificate(user).then(response => {
              resolve(response)
            })
          } else {
            // Do not update if it's placeholder
            if (
              values.certificateFile &&
              values.certificateFile.name !== 'placeholder'
            ) {
              this.uploadCerificate(user, values.certificateFile)
                .then(response => {
                  resolve(response)
                })
                .catch(error => {
                  reject(error)
                })
            } else {
              resolve(user)
            }
          }
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            const response: ErrorResponse = error.response.data

            if (response.statusCode === 422) {
              const {
                data: {message},
              } = response as ValidationError

              const errors = parseFormErrors<UserFormValues>(message)
              reject(errors)
            }

            if (response.statusCode === 400) {
              reject({message: 'Duplicate user found.'})
            }
          }

          reject(error)
        })
    })
  }

  /**
   * Disable/Enable user
   */
  toggleUser = async (user: User, isActive: boolean): Promise<User> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .put(`/users/${user.id}`, {isActive})
        .then((response: any) => {
          resolve(user)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Update certificate for the user
   * @param user User
   * @param file File
   */
  uploadCerificate = async (user: User, file: File): Promise<User> => {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('certificate', file)

      // Do the call
      return this.axios
        .post(`/users/${user.id}/certificate`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response: AxiosResponse<UserResponse>) => {
          resolve(response.data.data)
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            const response: ErrorResponse = error.response.data

            if (response.statusCode === 422) {
              const {
                data: {message},
              } = response as ValidationError

              const allErrors = parseFormErrors<any>(message)
              const errorsArr = []
              for (let property in allErrors) {
                if (allErrors.hasOwnProperty(property)) {
                  errorsArr.push(allErrors[property])
                }
              }

              // map all errors to certificateFile field
              // Certificate on it own has a lot of properties
              // This is kind off work around
              reject({
                certificateFile: errorsArr[0],
              })
            }

            reject({message: response.data.error})
          }

          reject(error)
        })
    })
  }

  /**
   * Delete certificate for user
   * @param user
   */
  deleteCertificate = async (user: User): Promise<User> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .delete(`/users/${user.id}/certificate`)
        .then((response: AxiosResponse<UserResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Load user certificate
   * @param user
   */
  viewCertificate = async (user: User): Promise<any> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/users/${user.id}/certificate`, {
          responseType: 'blob',
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Load all taxes
   * Possible params:
   * limit=10&order=asc&orderBy=createdAt&page=1&type=tax&search=HST
   * @param params
   */
  getTaxes = async (params: any): Promise<Tax[]> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get('/taxes', {params})
        .then((response: AxiosResponse<TaxesResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Creates new tax
   */
  createTax = async (values: TaxFormValues): Promise<Tax> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .post('/taxes', this._transformTaxForRequest(values))
        .then((response: AxiosResponse<TaxResponse>) => {
          resolve(response.data.data)
        })
        .catch((error: AxiosError) => {
          //@TODO move it to separate method
          if (error.response) {
            const response: ErrorResponse = error.response.data

            if (response.statusCode === 422) {
              const {
                data: {message},
              } = response as ValidationError

              const errors = parseFormErrors<TaxFormValues>(message)
              reject(errors)
            }

            if (response.statusCode === 400) {
              reject({message: 'Duplicate tax found.'})
            }
          }

          reject(error)
        })
    })
  }

  /**
   * Returns tax by id
   */
  getTax = async (id: string): Promise<Tax> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/taxes/${id}`)
        .then((response: AxiosResponse<TaxResponse>) => {
          resolve(response.data.data)
        })
        .catch((error: AxiosError) => {
          reject(error)
        })
    })
  }

  /**
   * Update tax
   */
  updateTax = async (values: TaxFormValues): Promise<Tax> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .put(`/taxes/${values.id}`, this._transformTaxForRequest(values))
        .then((response: AxiosResponse<TaxResponse>) => {
          resolve(response.data.data)
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            const response: ErrorResponse = error.response.data

            if (response.statusCode === 422) {
              const {
                data: {message},
              } = response as ValidationError

              const errors = parseFormErrors<TaxFormValues>(message)
              reject(errors)
            }

            if (response.statusCode === 400) {
              reject({message: 'Duplicate tax found.'})
            }
          }

          reject(error)
        })
    })
  }

  /**
   * Disable/Enable tax
   */
  toggleTax = async (tax: Tax, isActive: boolean): Promise<Tax> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .put(`taxes/${tax.id}`, {isActive})
        .then((response: AxiosResponse<TaxResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Returns array of HSCodes
   */
  getHSCodes = async (): Promise<HSChapter[]> => {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`/hs-codes/chapters/all`)
        .then((response: AxiosResponse<HSCodeResponse>) => {
          // add parent chapter to each code
          const chapters = response.data.data.map(chapter => {
            chapter.codes = chapter.codes.map(code => {
              return {
                ...code,
                chapter: chapter,
              }
            })

            return chapter as HSChapter
          })

          resolve(chapters)
        })
        .catch((error: AxiosError) => {
          reject(error)
        })
    })
  }

  /**
   * Returns details about hs code
   */
  getHSCode = async (params: {
    code: string | number
    findBy: 'code' | 'id'
  }): Promise<HSCode> => {
    const {code, ...rest} = params

    return new Promise((resolve, reject) => {
      this.axios
        .get(`/hs-codes/codes/${code}`, {
          params: rest,
        })
        .then((response: AxiosResponse<HSCodeDetailResponse>) => {
          resolve(response.data.data as HSCode)
        })
        .catch((error: AxiosError) => {
          reject(error)
        })
    })
  }

  /**
   * Fetch chapter details by Chapter id
   */
  getHSChapter = async (id: string): Promise<HSChapter> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/hs-codes/chapters/${id}`)
        .then((response: AxiosResponse<HSChapterResponse>) => {
          resolve(response.data.data)
        })
        .catch((error: AxiosError) => {
          reject(error)
        })
    })
  }

  /**
   * Fetch codes for particular chapter
   */
  getHSChapterCodes = async (
    params: any
  ): Promise<Partial<HSChapterCodesResponse>> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/hs-codes/chapters/${params.id}/codes`, {params})
        .then((response: AxiosResponse<HSChapterCodesResponse>) => {
          resolve({
            data: response.data.data,
            meta: response.data.meta,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Fetch chapters by params
   */
  getHSChapters = async (params: any): Promise<Partial<HSChaptersResponse>> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/hs-codes/chapters`, {params})
        .then((response: AxiosResponse<HSChaptersResponse>) => {
          resolve({
            data: response.data.data,
            meta: response.data.meta,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  createChapter = async (): Promise<HSChapter> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .post('/hs-codes/chapters')
        .then((response: AxiosResponse<HSChapterResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Update chapter with form values
   */
  updateChapter = async (values: ChapterFormValues): Promise<HSChapter> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .put(
          `/hs-codes/chapters/${values.id}`,
          this._transformChapterForRequest(values)
        )
        .then((response: AxiosResponse<HSChapterResponse>) => {
          resolve(response.data.data)
        })
        .catch((error: AxiosError) => {
          if (error.response) {
            const response: ErrorResponse = error.response.data

            if (response.statusCode === 422) {
              const {
                data: {message},
              } = response as ValidationError

              const errors = parseFormErrors<ChapterFormValues>(message)
              const codesErrors: any[] = []

              for (let property in errors) {
                if (errors.hasOwnProperty(property) && property === 'codes') {
                  const errorsObj = errors[
                    property as keyof ChapterFormValues
                  ] as any
                  for (let index in errorsObj) {
                    for (let property in errorsObj[index]) {
                      codesErrors.push(errorsObj[index][property])
                    }
                  }
                }
              }

              reject({
                ...errors,
                codes: codesErrors[0],
              })
            }

            if (response.statusCode === 400) {
              reject({message: 'Duplicate tax found.'})
            }
          }
          reject(error)
        })
    })
  }

  /**
   * Toggle chapter active/inactive
   */
  toggleChapter = async (
    chapter: HSChapter,
    isActive: boolean
  ): Promise<HSChapter> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .put(`/hs-codes/chapters/${chapter.id}`, {isActive})
        .then((response: AxiosResponse<HSChapterResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Create code inside chapter id
   * @param id string Chapter ID
   */
  createCode = async (id: string): Promise<HSCode> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .post(`/hs-codes/chapters/${id}/codes`)
        .then((response: AxiosResponse<HSCodesUpdateResponse>) =>
          resolve(response.data.data)
        )
        .catch(error => reject(error))
    })
  }

  /**
   * Load user certificate
   * @param user
   */
  exportHScodes = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/hs-codes/export`, {
          responseType: 'blob',
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Transactions
   */

  getTransactions = (params: any): Promise<Partial<TransactionsResponse>> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get('/transactions', {params})
        .then((response: AxiosResponse<TransactionsResponse>) => {
          resolve({
            data: response.data.data,
            meta: response.data.meta,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  getTransaction = (id: string): Promise<Transaction> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .get(`/transactions/${id}`)
        .then((resposnse: AxiosResponse<TransactionResponse>) => {
          resolve(resposnse.data.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Get total numbers for selected period in past
   */
  getTransactionsTotals = (params: any): Promise<TransactionsSummary> => {
    return new Promise((resolve, reject) => {
      this.axios
        .get('/transactions/totals', {params})
        .then((response: AxiosResponse<TransactionsSummaryResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get transaction filter options
   */
  getTransactionFilterOptions = (
    params: TransactionQuery
  ): Promise<TransactionFilterOptions> => {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`/transactions/filters`, {params})
        .then((response: AxiosResponse<TransactionFilterOptionsResponse>) => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
    })
  }

  /**
   * Get transaction groups
   */

  getTransactionGroup = (
    params: any
  ): Promise<{data: TransactionGroup[]; meta: PaginatedResponse}> => {
    return new Promise((resolve, reject) => {
      this.axios
        .get(`/transactions/grouped`, {params})
        .then((response: AxiosResponse<TransactionGroupResponse>) => {
          resolve({
            data: response.data.data,
            meta: response.data.meta,
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  /**
   * Auth related functionality
   */

  refreshToken = (): Promise<Token> => {
    return new Promise((resolve, reject) => {
      return this.axios
        .post('/refresh-token', {
          refreshToken: this.storage.get(this.storage.keys.refreshToken),
        })
        .then((response: AxiosResponse<TokenResponse>) => {
          this.saveTokens(response.data.data)
          resolve(response.data.data)
        })
        .catch(error => {
          this.invalidate()
          reject(error)
        })
    })
  }

  /**
   * Resign request if token was expired invalid
   */
  maybeResignRequest = () => {
    return this.refreshToken().catch(error => {
      // Token expired
      this.invalidate()
      return Promise.reject(error)
    })
  }

  saveTokens = (authTokens: Token) => {
    this.setAuthHeader(authTokens.token)
    this.storage.save(this.storage.keys.token, authTokens.token)
    this.storage.save(
      this.storage.keys.tokenExpirationDate,
      authTokens.expiresAt
    )
    this.storage.save(this.storage.keys.refreshToken, authTokens.refreshToken)
  }

  /**
   * Helper that removes needed keys from local storage
   */
  invalidate() {
    this.isRefreshingToken = false
    this.storage.remove(storage.keys.token)
    this.storage.remove(storage.keys.user)
    this.storage.remove(this.storage.keys.tokenExpirationDate)
    this.storage.remove(this.storage.keys.refreshToken)
  }

  /**
   * Helper to set Authentication header
   */
  setAuthHeader(token: string) {
    this.axios.defaults.headers.Authorization = `Bearer ${token}`
  }

  /**
   * User transformer serialize user form values for api needs
   */
  _transformUserForRequest(user: any) {
    const transformedUser = {...user} // clone user

    if (user.exemptions) {
      transformedUser.exemptions = user.exemptions.map((exemption: any) => ({
        tax: exemption.tax.id,
        hsCodes: exemption.hsCodes,
      }))

      // Remove empty property
      transformedUser.address = removeEmptyValuesFromObj(
        transformedUser.address
      )
    }

    return transformedUser
  }

  _transformTaxForRequest(tax: any) {
    const transformedTax = {...tax}

    /**
     * Transform exemptions
     */
    if (tax.exemptions) {
      transformedTax.exemptions = {
        hsCodes: [...transformedTax.exemptions.hsCodes],
        users: transformedTax.exemptions.users.map((userExemption: any) => ({
          user: userExemption.user.id,
          hsCodes: userExemption.hsCodes,
        })),
      }
    }

    return transformedTax
  }

  /**
   * Prepare chapter for request
   * @param values
   */
  _transformChapterForRequest(values: any) {
    let transformedChapter = {
      name: values.name,
      codes: values.codes,
    }

    //Transform codes for API
    if (transformedChapter.codes) {
      transformedChapter.codes = transformedChapter.codes.map((code: any) => ({
        id: code.id,
        isActive: code.isActive,
        description: code.description,
      }))
    }

    return transformedChapter
  }
}

/**
 * Axiom main instance
 */
export const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 7000,
  headers: {'Content-Type': 'application/json'},
})

/**
 * NetSweeper API single entry point use it to communicate
 */
export let netSweeperApi = new Apix({
  storage: storage,
  axios: instance,
  tokenRefreshTreshold: parseInt(
    process.env.REACT_APP_TOKEN_REFRESH_TRESHOLD || '5000',
    10
  ),
})
